import request from '@utils/fetch'

// 查询公众号菜单
export function weixinMenQueryAll(data) {
	return request({
		url: 'bxWeixinMenu/weixinMenQueryAll',
		method: 'post',
		data
	})
}

//新增公众号菜单
export function weixinMenu(data) {
	return request({
		url: 'bxWeixinMenu/weixinMenu',
		method: 'post',
		data
	})
}

//查询关键字管理
export function weixinKeywordQueryAll(data) {
	return request({
		url: 'bxWeixinKeyword/weixinKeywordQueryAll',
		method: 'post',
		data
	})
}

//新增关键字管理
export function weixinKeywordAdd(data) {
	return request({
		url: 'bxWeixinKeyword/weixinKeywordAdd',
		method: 'post',
		data
	})
}

//修改关键字管理
export function weixinKeywordUpdate(data) {
	return request({
		url: 'bxWeixinKeyword/weixinKeywordUpdate',
		method: 'post',
		data
	})
}

//删除关键字管理
export function weixinKeywordDelete(data) {
	return request({
		url: 'bxWeixinKeyword/weixinKeywordDelete',
		method: 'post',
		data
	})
}
